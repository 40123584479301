export const emptyVendor = {
  _id: '',
  businessName: '',
  businessId: '',
  contactEmail: '',
  contactPhoneNumber: '',
  accountId: '',
  contactPersonName: '',
  primaryCategoryId: '',
  secondaryCategoryId: [],
  description: '',
  type: '',
  street: '',
  city: '',
  cityName: '',
  postalCode: '',
  businessType: '',
  websiteUrl: '',
  facebookPageUrl: '',
  linkedInUrl: '',
  twitterUrl: '',
  linkedInUrl: '',
  instagramUrl: '',
  whatsappPhoneNumber: '',
  accountingBooksCertificationFileId: '',
  accountingBooksCertificationExpirationDate: '',
  taxDeductionAuthorizationFileId: '',
  proofOfBankAccountOwnershipFileId: '',
  businessLicenseFileId: ''
};

Object.freeze(emptyVendor);
