<template>
  <v-form class="position-relative">
    <div>
      <v-row>
        <v-col :cols="12">
          <v-card-title
            class="text-h5 textPrimary--text font-weight-semibold"
            >{{ $t('vendor.onboarding.vendorAdditionalDetails') }}</v-card-title
          >
        </v-col>
        <v-col :cols-md="12" :cols-lg="12">
          <cz-form-field :label="$t('social.websiteUrl')">
            <validation-provider
              rules="url"
              :name="$t('social.websiteUrl')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.websiteUrl"
                :placeholder="$t('social.websiteUrl')"
                :error-messages="errors"
                :append-icon="mdiLink"
                :readonly="readonly"
                dir="ltr"
                class="input-field"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('social.facebookUrl')">
            <validation-provider
              rules="url"
              :name="$t('social.facebookUrl')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.facebookPageUrl"
                :append-icon="mdiFacebook"
                :error-messages="errors"
                :placeholder="$t('social.facebookUrl')"
                :readonly="readonly"
                dir="ltr"
              />
            </validation-provider> </cz-form-field
        ></v-col>
        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('social.linkedInUrl')">
            <validation-provider
              rules="url"
              :name="$t('social.linkedInUrl')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.linkedInUrl"
                :error-messages="errors"
                :placeholder="$t('social.linkedInUrl')"
                type="tel"
                :append-icon="mdiLinkedin"
                :readonly="readonly"
                dir="ltr"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('social.twitterUrl')">
            <validation-provider
              rules="url"
              :name="$t('social.twitterUrl')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.twitterUrl"
                :error-messages="errors"
                :placeholder="$t('social.twitterUrl')"
                :append-icon="mdiTwitter"
                :readonly="readonly"
                dir="ltr"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('social.instagramUrl')">
            <validation-provider
              rules="url"
              :name="$t('social.instagramUrl')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.instagramUrl"
                :error-messages="errors"
                :append-icon="mdiInstagram"
                :placeholder="$t('social.instagramUrl')"
                :readonly="readonly"
                dir="ltr"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
        <v-col :md="12" :lg="3">
          <cz-form-field :label="$t('social.whatsappPhoneNum')">
            <validation-provider
              rules="phoneNumber"
              :name="$t('social.whatsappPhoneNum')"
              v-slot="{ errors }"
            >
              <cz-input
                v-model="localForm.whatsappPhoneNumber"
                :error-messages="errors"
                :append-icon="mdiWhatsapp"
                :readonly="readonly"
                :placeholder="$t('social.whatsappPhoneNum')"
                type="tel"
                dir="ltr"
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { CzFormField, CzInput, CzIcon } from '@/components';
import {
  mdiFacebook,
  mdiLinkedin,
  mdiTwitter,
  mdiWhatsapp,
  mdiLink,
  mdiInstagram
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
export default {
  name: 'OnBoardingBusinessDetails',
  components: {
    CzFormField,
    CzInput,
    CzIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.localForm = cloneDeep(this.value);
  },
  data() {
    return {
      mdiFacebook,
      mdiLinkedin,
      mdiTwitter,
      mdiWhatsapp,
      mdiLink,
      mdiInstagram,
      localForm: null
    };
  },
  watch: {
    localForm: {
      deep: true,
      handler(value) {
        if (!isEqual(value, this.value)) {
          this.$emit('input', value);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-input input {
  text-align: right !important;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
