<template>
  <content-layout :loading="loading" class="pa-0">
    <validation-observer tag="div" ref="observer" v-slot="{ invalid }">
      <div
        style="position: sticky; top: 64px; z-index: 101"
        class="py-4 background"
      >
        <v-card :height="100" v-if="!loading" outlined flat rounded="lg">
          <div class="d-flex full-width full-height">
            <v-btn text :width="100" :height="100" @click="onClose(false)">
              <v-icon :size="25">{{ mdiClose }}</v-icon>
            </v-btn>
            <div
              class="flex-grow-1 align-center d-flex flex-column justify-center position-relative"
              v-for="(step, index) in filteredSteps"
              :key="`step_${index}`"
            >
              <div
                class="d-flex align-center full-width justify-center"
                style="column-gap: 10px"
              >
                <cz-icon
                  :size="26"
                  :src="step.icon"
                  :color="
                    currentStep === index ? 'primary darken-2' : 'textPrimary'
                  "
                />
                <div
                  class="text-title"
                  :class="
                    currentStep === index
                      ? 'font-weight-bold'
                      : 'font-weight-medium'
                  "
                >
                  {{ step.text }}
                </div>
              </div>
              <div v-if="currentStep === index" class="indicator" />
            </div>

            <div
              class="d-flex full-height justify-center align-center"
              style="width: 240px"
            >
              <v-progress-circular
                indeterminate
                v-if="saving"
                color="primary"
              />
              <template v-else>
                <v-btn
                  style="height: 100%"
                  text
                  :width="120"
                  @click="onPrev"
                  :disabled="saving || currentStep === 0"
                >
                  <div>
                    <v-icon :size="50">{{ mdiChevronRight }}</v-icon>
                    <div class="font-weight-semibold text-title">
                      {{ $t('common.prev') }}
                    </div>
                  </div>
                </v-btn>
                <v-btn
                  style="height: 100%"
                  text
                  :width="120"
                  @click="onNext"
                  :disabled="
                    invalid || currentStep === filteredSteps.length - 1
                  "
                >
                  <div>
                    <v-icon :size="50">{{ mdiChevronLeft }}</v-icon>
                    <div class="text-title font-weight-semibold">
                      {{ $t('common.next') }}
                    </div>
                  </div>
                </v-btn>
              </template>
            </div>
          </div>
        </v-card>
      </div>

      <div>
        <v-overlay :value="overlay" v-if="submitting">
          <v-progress-circular
            style="position: fixed; top: 50%; left: 50%"
            indeterminate
            :size="60"
          />
        </v-overlay>

        <v-progress-circular
          style="position: fixed; top: 50%; left: 50%"
          indeterminate
          :size="40"
          color="primary"
          v-if="loading"
        />
        <v-container
          v-else
          fluid
          class="d-flex full-height px-0"
          style="gap: 20px"
        >
          <v-card
            class="flex-grow-1"
            :loading="saving"
            :disabled="saving"
            outlined
            rounded="lg"
            flat
          >
            <v-card-text v-if="form">
              <business-details
                v-model="form"
                :readonly="isReadOnly"
                v-if="currentStep === 0"
                :edit-mode="isEditMode || vendorCreated"
              />
              <business-classification
                v-model="form"
                v-else-if="currentStep === 1"
                :primary-categories="primaryCategories"
                :secondary-categories="secondaryCategories"
                :readonly="isReadOnly"
              />
              <business-docs
                v-model="form"
                v-else-if="currentStep === 2"
                @change="onBusinessDocChange"
                @remove="onBusinessDocRemove"
                :readonly="isReadOnly"
                :is-business-license-required="businessLicenseRequired"
              />
              <business-additional-details
                v-model="form"
                v-else-if="currentStep === 3"
                :readonly="isReadOnly"
              />
              <on-boarding-summery
                :form="form"
                v-if="currentStep === 4"
                :primary-categories="primaryCategories"
                :secondary-categories="secondaryCategories"
              />
            </v-card-text>
          </v-card>
          <div
            class="d-flex flex-column justify-space-between mt-4"
            style="height: calc(100vh - 260px)"
          >
            <div
              v-if="
                (isLastStep && isSubmitButtonVisible) || isVendorStatusReturned
              "
              style="row-gap: 35px"
              class="d-flex flex-column"
            >
              <on-boarding-return-reason
                v-if="isVendorStatusReturned"
                :reason="vendor.reason"
              />
              <onboarding-submit
                v-if="isLastStep && isSubmitButtonVisible"
                @submit="onSubmit"
              />
            </div>

            <div v-else />
            <img
              :height="200"
              :src="onboarding"
              class="position-relative"
              style="top: 15px"
            />
          </div>
        </v-container>
        <cz-prompt-dialog
          v-if="closeOnBoardingDialog.show"
          v-model="closeOnBoardingDialog.show"
          v-bind="closeOnBoardingDialog"
          @cancel="closeOnBoardingDialog.show = false"
          @done="onClose(true)"
        />
        <v-dialog
          :width="500"
          v-model="submitSucceedDialog.show"
          v-if="submitSucceedDialog.show"
          v-bind="submitSucceedDialog"
        >
          <v-card class="overflow-y-hidden pa-6" :width="500">
            <div class="d-flex justify-center full-width">
              <img :src="submitSucceed" :width="350" />
            </div>
            <v-card-title class="text-h4">
              {{ $t('vendor.onboarding.submitSucceedTitle') }}
            </v-card-title>
            <v-card-text class="text-body-1">
              {{ $t('vendor.onboarding.submitSucceedMessage') }}
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" large @click="onComplete">{{
                $t('common.close')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </validation-observer>
  </content-layout>
</template>

<script>
import onboarding from '@/assets/onboarding.svg';
import submitSucceed from '@/assets/illustrations/submit-succeed.svg';
import { CzInput, CzFormField, CzIcon } from '@/components';
import BusinessDetails from '../components/OnBoardingBusinessDetails.vue';
import BusinessAdditionalDetails from '../components/OnBoardingAdditionalDetails.vue';
import BusinessDocs from '../components/OnBoardingDocuments.vue';
import BusinessClassification from '../components/OnBoardingBusinessClassification.vue';
import OnBoardingSummery from '../components/OnBoardingSummery.vue';
import pattern from '@/assets/pattern.png';
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiClose,
  mdiInformation,
  mdiChevronRight,
  mdiChevronLeft,
  mdiAccountDetailsOutline,
  mdiShapeOutline,
  mdiFileDocumentMultipleOutline,
  mdiDotsHorizontalCircleOutline,
  mdiSendCheckOutline
} from '@mdi/js';
import { mapFields } from 'vuex-map-fields';
import { emptyVendor } from '../constants';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { mapActions } from 'vuex';
import ContentLayout from '@/layouts/ContentLayout.vue';
import { format, parse } from 'date-fns';
import {
  getVendorPrimaryCategories,
  getVendorSecondaryCategories,
  updateVendor,
  getVendor,
  uploadFiles
} from '@/core/api';
export default {
  name: 'VendorOnBoarding',
  components: {
    CzInput,
    CzFormField,
    CzIcon,
    BusinessDetails,
    BusinessAdditionalDetails,
    BusinessDocs,
    BusinessClassification,
    OnBoardingSummery,
    ContentLayout,
    CzPromptDialog: () => import('@/components/CzPromptDialog.vue'),
    OnboardingSubmit: () => import('../components/OnBoardingSubmit.vue'),
    OnBoardingReturnReason: () =>
      import('../components/OnBoardingReturnReason.vue')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  created() {
    this.loadData();
    this.loading = this.isEditMode && !this.vendors?.length;
  },
  computed: {
    ...mapFields('auth', ['account', 'accountId']),
    ...mapFields('vendor', ['vendor', 'vendors']),
    businessLicenseRequired() {
      if (!this.vendor?.primaryCategoryId) {
        return false;
      }

      const category = this.primaryCategories?.find(
        (item) => item._id === this.vendor.primaryCategoryId
      );
      return category?.businessLicenseRequired;
    },
    isEditMode() {
      return this.id !== 'new';
    },
    isFormChanged() {
      return !isEqual(this.form, this.formCopy);
    },
    isLastStep() {
      return this.currentStep === this.filteredSteps.length - 1;
    },
    isReadOnly() {
      return (
        (this.isVendorStatusPending ||
          this.isVendorStatusBlocked ||
          this.isVendorStatusActive) &&
        this.isEditMode
      );
    },
    isSubmitButtonVisible() {
      return (
        !this.isVendorStatusActive &&
        !this.isVendorStatusBlocked &&
        !this.isVendorStatusPending
      );
    },
    isVendorStatusBlocked() {
      return this.vendor?.status === 'blocked';
    },
    isVendorStatusPending() {
      return this.vendor?.status === 'pending_approval';
    },
    isVendorStatusActive() {
      return this.vendor?.status === 'active';
    },
    isVendorStatusReturned() {
      return this.vendor?.status === 'returned';
    },
    filteredSteps() {
      if (this.isVendorStatusPending) {
        return this.steps.filter(
          (step) => step !== this.$t('vendor.onboarding.summery')
        );
      } else {
        return this.steps;
      }
    }
  },
  data() {
    return {
      pattern,
      onboarding,
      submitSucceed,
      mdiArrowLeft,
      mdiChevronLeft,
      mdiChevronRight,
      mdiInformation,
      mdiClose,
      mdiArrowRight,
      currentStep: 0,
      submitting: false,
      saving: false,
      vendorCreated: false,
      loading: false,
      closeOnBoardingDialog: {
        show: false,
        title: this.$t('common.notice'),
        message:
          'זיהינו שבוצעו שינויים בטופס, רצינו לעדכן אותך שהשינויים לא ישמרו. האם אתה בטוח שברצונך להמשיך ללא שמירה?'
      },
      submitSucceedDialog: {
        show: false
      },

      steps: [
        {
          text: this.$t('vendor.onboarding.vendorDetails'),
          icon: mdiAccountDetailsOutline
        },
        {
          text: this.$t('vendor.onboarding.vendorClassification'),
          icon: mdiShapeOutline
        },
        {
          text: this.$t('vendor.onboarding.vendorDocuments'),
          icon: mdiFileDocumentMultipleOutline
        },
        {
          text: this.$t('vendor.onboarding.vendorAdditionalDetails'),
          icon: mdiDotsHorizontalCircleOutline
        },
        {
          text: this.$t('vendor.onboarding.summery'),
          icon: mdiSendCheckOutline
        }
      ],
      form: null,
      formCopy: null,
      primaryCategories: [],
      secondaryCategories: [],
      businessDocsFilesToUpload: []
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    ...mapActions('vendor', [
      'switchVendor',
      'createVendor',
      'setSelectedVendor'
    ]),
    async applyVendorToForm() {
      if (!this.isEditMode && !this.vendorCreated) {
        this.form = cloneDeep({
          ...emptyVendor,
          accountId: this.account._id
        });
        this.formCopy = cloneDeep(this.form);
      } else if (this.vendor && this.vendorCreated) {
        this.loading = false;
        if (this.vendor.accountingBooksCertificationExpirationDate) {
          this.vendor.accountingBooksCertificationExpirationDate = format(
            new Date(this.vendor.accountingBooksCertificationExpirationDate),
            'dd/MM/yyyy'
          );
        }

        this.form = {
          ...emptyVendor,
          ...cloneDeep(this.vendor)
        };
        this.formCopy = cloneDeep(this.form);
      } else {
        if (!this.vendor) {
          const vendor = this.vendors?.find((item) => item._id === this.id);
          if (!vendor) {
            return;
          } else {
            this.vendor = vendor;
          }
        }

        if (this.vendor.accountingBooksCertificationExpirationDate) {
          this.vendor.accountingBooksCertificationExpirationDate = format(
            new Date(this.vendor.accountingBooksCertificationExpirationDate),
            'dd/MM/yyyy'
          );
        }

        this.loading = false;
        this.form = {
          ...emptyVendor,
          ...cloneDeep(this.vendor)
        };
        this.formCopy = cloneDeep(this.form);
      }
    },
    async loadData() {
      try {
        const [primaryCategoriesRes, secondaryCategoriesRes] =
          await Promise.all([
            getVendorPrimaryCategories(),
            getVendorSecondaryCategories()
          ]);
        this.primaryCategories = primaryCategoriesRes.data;
        this.secondaryCategories = secondaryCategoriesRes.data;
      } finally {
      }
    },
    onBusinessDocChange(item) {
      //add file to the files to upload array (if not exists)
      const index = this.businessDocsFilesToUpload.findIndex(
        (i) => i.id === item.id
      );
      if (index !== -1) {
        this.businessDocsFilesToUpload[index] = item;
      } else {
        this.businessDocsFilesToUpload.push(item);
      }
    },
    onBusinessDocRemove(item) {
      // remove from files to upload
      const index = this.businessDocsFilesToUpload.indexOf(item);
      if (index !== -1) {
        this.businessDocsFilesToUpload.splice(index, 1);
      }
    },
    async saveVendor(moveToNextStep = true) {
      if (isEqual(this.form, this.formCopy)) {
        if (moveToNextStep) {
          this.currentStep = this.currentStep + 1;
        }
        return;
      }

      try {
        this.saving = true;

        // handle documents here in case user select documents
        // or update exisitng documents, we need to first upload them and then
        // attach the document id to the vendor object
        if (this.businessDocsFilesToUpload?.length) {
          const promises = this.businessDocsFilesToUpload.map((item) => {
            return uploadFiles([item.file], this.account?._id);
          });
          const results = await Promise.all(promises);
          for (let i = 0; i < results.length; i++) {
            const [uploadedFile] = results[i];
            const idField = this.businessDocsFilesToUpload[i].idField;
            this.form[idField] = uploadedFile._id;
          }
        }
        if (this.form.accountingBooksCertificationExpirationDate) {
          this.form.accountingBooksCertificationExpirationDate = parse(
            this.form.accountingBooksCertificationExpirationDate,
            'dd/MM/yyyy',
            new Date()
          );
        }

        // if vendor has no identifer
        // then we need to create it
        if (!this.isEditMode && !this.vendorCreated) {
          const vendor = await this.createVendor(this.form);
          this.vendorCreated = true;
          this.setSelectedVendor(vendor._id);
          this.$router.replace({
            name: 'vendor-onboarding',
            params: {
              id: vendor._id
            }
          });
        } else {
          this.vendor = await updateVendor(this.vendor._id, this.form, {
            $populate: 'files'
          });
        }
        this.applyVendorToForm();
        if (moveToNextStep) {
          this.currentStep = this.currentStep + 1;
        }
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.saving = false;
      }
    },
    onClose(force = false) {
      if (!this.isFormChanged || force) {
        this.$router.replace({
          name: 'vendor-home',
          params: {
            id: this.vendor?._id || 'new'
          }
        });
      } else {
        this.closeOnBoardingDialog.show = true;
      }
    },
    onNext() {
      if (this.currentStep === this.filteredSteps.length - 1) {
        return;
      }

      this.saveVendor();
    },
    onPrev() {
      if (this.currentStep === 0) {
        return;
      }
      // when going back without saving anything, we
      // need to set the local form copy to the form in order
      // to disable changes done (if any) in the current screen before moving back
      this.form = cloneDeep(this.formCopy);
      this.currentStep = this.currentStep - 1;
    },
    async onSubmit() {
      try {
        this.submitting = true;
        this.form = {
          ...this.form,
          status: 'pending_approval'
        };

        this.form.accountingBooksCertificationExpirationDate = parse(
          this.form.accountingBooksCertificationExpirationDate,
          'dd/MM/yyyy',
          new Date()
        );

        this.vendor = await updateVendor(this.vendor._id, this.form);
        this.submitSucceedDialog.show = true;
      } finally {
        this.submitting = false;
      }
    },
    onComplete() {
      this.submitSucceedDialog.show = true;
      this.$router.replace({
        name: 'vendor-home',
        params: {
          id: this.vendor._id
        }
      });
    }
  },
  watch: {
    vendors: {
      immediate: true,
      handler() {
        this.applyVendorToForm();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-field {
  width: 50%;
}
.wizard-step {
  border-bottom: 15px solid pink;
}

.indicator {
  position: absolute;
  bottom: 0;
  background-color: var(--v-primary-base);
  width: 100%;
  height: 7px;
}

.toolbar {
  left: 0;
  right: 0;
}

::v-deep .v-toolbar__content {
  padding: 0 !important;
}
</style>
